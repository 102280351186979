import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Spacer from "../components/Spacer";
import "../styles/services.css";

const ServicesPage = () => {
  return (
    <Layout pageTitle="Service Page">
      <div className="mainpage servicepage">
        <div className="landinggrid left servicesgrid">
          <div className="landingwhitespace"></div>
          <div className="landingwords text">
            <p>
              Creating spaces that can influence those changes is what inspires
              us every day.
            </p>
          </div>

          <StaticImage
            alt="Service Landing"
            src="../images/services.png"
            className="image landingimage"
          />
        </div>

        <Spacer classes="h30" />
        <Link to="/portfolio/">
          <div className="grid3 servicesgrid3">
            <StaticImage
              alt="Architecture Design"
              src="../images/servicesA.png"
              className="image landingimage"
            />

            <h2 className="h2">Architecture Design</h2>

            <div className="text">
              We provide people centric designs that make effective use of space
              by anticipating possible problems beforehand.
            </div>
          </div>
        </Link>

        <Spacer classes="h30" />

        <Link to="/portfolio/">
          <div className="grid3 servicesgrid3 left">
            <StaticImage
              alt="Interior Design"
              src="../images/servicesI.jpg"
              className="image landingimage"
            />
            <h2 className="h2">Interior Design</h2>
            <div className="text">
              We fill your home interiors with a unique style that resonates
              with your personality and emotions.
            </div>
          </div>
        </Link>

        <Spacer classes="h30" />

        <Link to="/contact/">
          <div className="grid3 servicesgrid3">
            <StaticImage
              alt="Liaison Consultancy"
              src="../images/servicesL.jpg"
              className="image landingimage"
            />
            <h2 className="h2">Liaison Consultancy</h2>
            <div className="text">
              No more delays from government paperwork! We make sure you get
              timely approvals from concerned authorities. This service is
              currently available for Pune clients exclusively.
            </div>
          </div>
        </Link>

        <Spacer classes="h50" />

        <div className="centerthis processtext">
          <h2 className="text">PROCESS</h2>
        </div>
        <div className="designline centerthis"></div>
        <div className="centerthis text subprocesstext">
          <h2 className="text">Pre-Construction</h2>
        </div>

        <section className="processgrid">
          <article>
            <p>INITIATE</p> <p className="processnumber">I</p>
          </article>

          <div className="processline"></div>

          <article>
            <p className="processnumber">II</p>
            <p>PLAN</p>
          </article>
        </section>

        <div className="centerthis text subprocesstext">
          <h2 className="text">Construction</h2>
        </div>

        <section className="processgrid">
          <article>
            <p>WORK</p> <p className="processnumber">III</p>
          </article>

          <div className="processline"></div>

          <article>
            <p className="processnumber">IV</p>
            <p>EXECUTE</p>
          </article>
        </section>

        <div className="centerthis text subprocesstext">
          <h2 className="text">Post-Construction</h2>
        </div>

        <section className="processgrid processend">
          <div className="processline"></div>
          <article>
            <p className="processnumber">V</p>
            <p>COMPLETION</p>
          </article>
          <div className="processlineh"></div>
        </section>

        <Spacer classes="h50" />

        <Link to="/portfolio/venkatesh-buildcon/" aria-label="Venkatesh">
          <StaticImage
            alt="Venkatesh"
            src="../images/1556 - Venkatesh Buidcon Balewadi Cam 002_Dusk.jpg"
            className="image fullwidth mobilecropped"
          />
        </Link>

        <Spacer classes="h50" />
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="Services" />;

export default ServicesPage;
